<template>
  <div>
    <GridColumnMenuCheckboxFilter
      :column="column"
      :filter="filter"
      :filterable="filterable"
      :search-box="false"
      :data-items="gridData"
      :expanded="true"
      @expandchange="expandChange"
      @closemenu="closeMenu"
      @filterchange="filterChange"
    />
  </div>
</template>
<script>
import { GridColumnMenuCheckboxFilter } from '@progress/kendo-vue-grid'

export default {
  components: {
    GridColumnMenuCheckboxFilter: GridColumnMenuCheckboxFilter
  },
  props: {
    column: Object,
    filter: Object,
    filterable: Boolean,
    uniqueData: {
      type: Boolean,
      default: true
    },
    gridData: Array
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      this.$emit('filterchange', newDescriptor, e)
    }
  }
}
</script>
